import packets from "./packets"

function handle(app, message) {
  let packet = JSON.parse(message.data)
  console.log(packet)
  let type = packet.type
  switch (type) {
    case packets.HANDSHAKE_RESPONSE:
      handleHandshakeResponse(app, packet)
      break
    case packets.UPDATE_PROJECTOR:
      handleUpdateProjector(app, packet)
      break
    case packets.CHECKIN_RESPONSE:
      handleCheckinResponse(app, packet)
      break
    case packets.CHECKIN_SUCCESS:
      handleCheckinSuccess(app, packet)
      break
    case packets.NOTIFY_TEAM_ASSIGNMENT:
      handleNotifyTeamAssignment(app, packet)
      break
    case packets.UPDATE_ADMIN:
      handleUpdateAdmin(app, packet)
      break
    case packets.ADMIN_SEND_TEAM:
      handleAdminSendTeam(app, packet)
      break
    case packets.NOTIFY_DISCONNECT:
      handleNotifyDisconnect(app, packet)
      break
    default:
      break
  }
}

function handleHandshakeResponse(app, packet) {
  app.setState({
    loggedIn: packet.accepted,
    salt: packet.salt,
    wrongPasswordOpen: !packet.accepted,
  })
}

function handleUpdateProjector(app, packet) {
  app.setState({
    projectorAssignments: packet.assignments,
    projectorQueue: packet.queue,
    projectorTeamsRemaining: packet.teamsRemaining,
  })
}

function handleCheckinResponse(app, packet) {
  app.setState({
    checkinTeamNumber: packet.teamNumber,
    checkinText: packet.text,
    checkinEarlyText: packet.earlyText,
    checkinOk: packet.ok,
    checkinEarly: packet.early,
    checkinComplete: packet.complete,
    checkinTime: packet.time,
    checkinDisqualified: packet.disqualified,
    checkinAwaitingConfirm: true,
  })
}

function handleNotifyTeamAssignment(app, packet) {
  app.setState({
    stationTeamAssignment: packet.assignment,
    stationTaskAN: packet.taskAN,
    stationTaskBN: packet.taskBN,
  })
}

function handleCheckinSuccess(app, packet) {
  app.setState({
    checkinConfirmed: true,
  })
}

function handleUpdateAdmin(app, packet) {
  app.setState({
    adminStations: packet.stations,
    adminDisabledStations: packet.disabledStations,
    adminTestLayout: packet.testLayout,
  })
}

function handleAdminSendTeam(app, packet) {
  app.setState({
    adminTeamNumber: packet.team.number,
    adminTeam: packet.team,
  })
}

function handleNotifyDisconnect(app, packet) {
  app.setState({
    disconnected: true,
  })
}

export default handle
