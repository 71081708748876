const packets = {
  // Out
  HANDSHAKE: "handshake",
  HANDSHAKE_RESPONSE: "handshakeResponse",
  CHECKIN_INIT: "checkinInit",
  CHECKIN_CONFIRM: "checkinConfirm",
  STATION_RESULT: "stationResult",
  ADMIN_COMMAND: "adminCommand",
  ADMIN_TOGGLE_STATION: "adminToggleStation",
  ADMIN_TOGGLE_TEST_LAYOUT: "adminToggleTestLayout",
  ADMIN_REQUEST_TEAM: "adminRequestTeamPacket",

  // In
  UPDATE_PROJECTOR: "updateProjector",
  CHECKIN_RESPONSE: "checkinResponse",
  CHECKIN_SUCCESS: "checkinSuccess",
  ADMIN_LOG_EVENT: "adminLogEvent",
  NOTIFY_TEAM_ASSIGNMENT: "notifyTeamAssignment",
  UPDATE_ADMIN: "updateAdmin",
  ADMIN_SEND_TEAM: "adminSendTeam",
  NOTIFY_DISCONNECT: "notifyDisconnect",
}

export default packets
