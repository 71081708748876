import React from "react"
import handlePacket from "./packetHandler"
import dispatcher from "./packetDispatcher"
import modes from "./modes"
import math from "./math"
import sha256 from "js-sha256"
import ReconnectingWebSocket from "reconnecting-websocket"

import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import CssBaseline from "@material-ui/core/CssBaseline"
import TextField from "@material-ui/core/TextField"
import Paper from "@material-ui/core/Paper"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormControl from "@material-ui/core/FormControl"
import Chip from "@material-ui/core/Chip"
import Snackbar from "@material-ui/core/Snackbar"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import SnackbarContent from "@material-ui/core/SnackbarContent"
import ErrorIcon from "@material-ui/icons/Error"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import InputLabel from "@material-ui/core/InputLabel"
import { red } from "@material-ui/core/colors"
import { Box, Switch } from "@material-ui/core"

const URL = "demo-socket.ened.cloud/socket"
//const URL = "localhost:80/socket"

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      // General
      connected: false,
      loggedIn: false,
      station: 1,
      mode: modes.station,
      password: "",
      salt: "",
      wrongPasswordOpen: false,
      disconnected: false,

      // Checkin
      checkinTeamNumber: 0,
      checkinAwaitingConfirm: false,
      checkinText: "Waiting for response...",
      checkinEarlyText: "",
      checkinOk: false,
      checkinEarly: false,
      checkinComplete: false,
      checkinDisqualified: false,
      checkinConfirmed: false,

      // Stations
      stationTeamAssignment: -1,
      stationConfirmationMode: false,
      stationTaskAN: 0,
      stationTaskAPredictedX: 0,
      stationTaskAPredictedY: 0,
      stationTaskAActualX: 0,
      stationTaskAActualY: 0,
      stationTaskAXoob: false,
      stationTaskAYoob: false,
      stationTaskBN: 0,
      stationTaskBPredictedX: 0,
      stationTaskBPredictedY: 0,
      stationTaskBActualX: 0,
      stationTaskBActualY: 0,
      stationTaskBXoob: false,
      stationTaskBYoob: false,
      stationComment: "",

      // Projector
      projectorAssignments: {},
      projectorQueue: [],
      projectorTeamsRemaining: 0,

      // Admin
      adminStations: 0,
      adminTestLayout: false,
      adminDisabledStations: [],
      adminStationToDisable: 1,
      adminTeamToRequest: 0,
      adminTeamNumber: 0,
      adminTeam: {},
    }

    this.socket = new ReconnectingWebSocket("wss://" + URL, [], {
      minReconnectionDelay: Math.random() * 10,
      maxReconnectionDelay: 2000 + Math.random() * 2000,
      maxRetries: 1200,
    })
    this.socket.onmessage = msg => this.setState(handlePacket(this, msg))
    this.socket.onclose = () => this.setState({ connected: false })
    this.socket.onerror = () => this.setState({ connected: false })
    this.socket.onopen = () => this.socketConnected()
  }

  componentDidMount() {
    document.addEventListener("keydown", e => this.keyPress(e), false)
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", e => this.keyPress(e), false)
  }

  socketConnected() {
    if (this.state.loggedIn && !this.state.disconnected) {
      setTimeout(() => {
        if (this.state.connected) {
          dispatcher.handshake(this)
        }
      }, 500)
    }
    this.setState({ connected: true })
  }

  keyPress(e) {
    if (e.keyCode === 13) {
      if (this.state.loggedIn) {
        if (this.state.mode === modes.gatekeeper) {
          if (this.state.checkinAwaitingConfirm && this.state.checkinOk) {
            dispatcher.gatekeeperConfirm(this)
          } else {
            dispatcher.gatekeeperInit(this)
          }
        } else if (this.state.mode === modes.station) {
          if (this.state.stationConfirmationMode) {
            dispatcher.stationResults(this, false)
          } else {
            if (
              !this.state.stationConfirmDisqualify &&
              this.state.stationTeamAssignment > -1
            ) {
              this.setState({ stationConfirmationMode: true })
            }
          }
        }
      } else {
        if (!this.state.disconnected) {
          dispatcher.handshake(this)
        }
      }
    }
  }

  getMainMenuContent() {
    function getTypeStationNumberContent(that) {
      if (!(that.state.mode === modes.station)) {
        return <React.Fragment />
      }
      return (
        <React.Fragment>
          <Grid item>
            <Typography variant="h6">Type your station number</Typography>
          </Grid>
          <Grid item>
            <TextField
              id="station"
              label="Station"
              value={that.state.station}
              onChange={event => {
                that.setState({
                  station: event.target.value,
                })
              }}
              margin="normal"
              type="number"
              variant="outlined"
            />
          </Grid>
        </React.Fragment>
      )
    }

    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ width: "100%", height: "100%" }}
      >
        <Grid item>
          <Typography variant="h1" component="h2">
            Project 5 Subtask 1
          </Typography>
        </Grid>
        <Grid item>
          <Grid item>
            <Typography variant="h6">Select your assigned role</Typography>
          </Grid>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={this.state.mode}
              onChange={event => this.setState({ mode: event.target.value })}
            >
              <FormControlLabel
                value={modes.station}
                control={<Radio />}
                label={modes.station}
              />
              <FormControlLabel
                value={modes.gatekeeper}
                control={<Radio />}
                label={modes.gatekeeper}
              />
              <FormControlLabel
                value={modes.projector}
                control={<Radio />}
                label={modes.projector}
              />
              <FormControlLabel
                value={modes.admin}
                control={<Radio />}
                label={modes.admin}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        {getTypeStationNumberContent(this)}
        <Grid item>
          <Typography variant="h6">
            Type your provided role password (do not let students see)
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            id="password"
            label="Password"
            value={this.state.password}
            onChange={event => {
              this.setState({
                password: event.target.value,
              })
            }}
            margin="normal"
            type="password"
            variant="outlined"
          />
        </Grid>
        <Grid item>
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={() => dispatcher.handshake(this)}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    )
  }

  getGatekeeperContent() {
    function getConfirmBody(app) {
      let result = []
      result.push(
        <Grid item>
          <Typography variant="h2">
            Team {app.state.checkinTeamNumber}
          </Typography>
        </Grid>
      )
      if (app.state.checkinOk) {
        if (app.state.checkinEarly) {
          result.push(
            <Grid item>
              <Typography variant="h4" style={{ color: "red" }}>
                This team is early. Check in only if queue is empty.
              </Typography>
            </Grid>
          )
          result.push(
            <Grid item>
              <Typography variant="h4" style={{ color: "red" }}>
                {app.state.checkinEarlyText}
              </Typography>
            </Grid>
          )
        }
        if (app.state.checkinComplete) {
          result.push(
            <React.Fragment>
              <Grid item>
                <Typography variant="h4" style={{ color: "red" }}>
                  This team has already done their demo!
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h4" style={{ color: "red" }}>
                  Ask the demo coordinator before checking in.
                </Typography>
              </Grid>
            </React.Fragment>
          )
        }
        if (app.state.checkinDisqualified) {
          result.push(
            <React.Fragment>
              <Grid item>
                <Typography variant="h4" style={{ color: "red" }}>
                  This team is marked as disqualified!
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h4" style={{ color: "red" }}>
                  Ask the demo coordinator before checking in.
                </Typography>
              </Grid>
            </React.Fragment>
          )
        }
        result.push(
          <Grid item>
            <Typography variant="h5">
              Make sure one of the following people is standing in front of you
              before clicking confirm!
            </Typography>
          </Grid>
        )
        result.push(
          <Grid item>
            <Typography variant="h6">{app.state.checkinText}</Typography>
          </Grid>
        )
        result.push(
          <Grid item>
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={() => dispatcher.gatekeeperConfirm(app)}
            >
              Confirm
            </Button>
          </Grid>
        )
        result.push(
          <Grid item>
            <Button
              size="large"
              variant="contained"
              color="secondary"
              onClick={() => app.setState({ checkinAwaitingConfirm: false })}
            >
              Back
            </Button>
          </Grid>
        )
      } else {
        result.push(
          <Grid item>
            <Typography variant="h6">{app.state.checkinText}</Typography>
          </Grid>
        )
        result.push(
          <Grid item>
            <Button
              size="large"
              variant="contained"
              color="secondary"
              onClick={() => app.setState({ checkinAwaitingConfirm: false })}
            >
              Back
            </Button>
          </Grid>
        )
      }
      return result
    }

    if (this.state.checkinAwaitingConfirm) {
      return (
        <Grid
          container
          direction="column"
          spacing={2}
          alignItems="center"
          justifyContent="center"
          style={{ height: "100%" }}
        >
          {getConfirmBody(this)}
        </Grid>
      )
    } else {
      return (
        <Grid
          container
          direction="column"
          spacing={2}
          alignItems="center"
          justifyContent="center"
          style={{ height: "100%" }}
        >
          <Grid item>
            <Typography variant="h2">Gatekeeper</Typography>
          </Grid>
          <Grid item>
            <TextField
              id="team-number"
              label="Team Number"
              value={this.state.checkinTeamNumber}
              onChange={event =>
                this.setState({ checkinTeamNumber: event.target.value })
              }
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={() => dispatcher.gatekeeperInit(this)}
            >
              CHECK IN
            </Button>
          </Grid>
        </Grid>
      )
    }
  }

  getProjectorContent() {
    const assignments = []
    let index = 0
    for (let key in this.state.projectorAssignments) {
      let list = assignments[Math.floor(index / 7)]
      if (list === undefined) {
        list = []
        assignments.push(list)
      }
      list.push(
        <Typography variant="h3" style={{ margin: 8 }}>
          Team {key} → Station {this.state.projectorAssignments[key]}
        </Typography>
      )
      index++
    }
    let queueIndex = 0
    let queue = ""
    for (let team of this.state.projectorQueue) {
      if (queue.length !== 0) {
        queue += ", "
      }

      if (queueIndex > 5) {
        queue += "and "
        queue += this.state.projectorQueue.length - queueIndex
        queue += " more..."
        break
      }
      queue += team
      queueIndex++
    }
    if (queue.length === 0) {
      queue = "None"
    }
    return (
      <Grid container spacing={0} direction="column" style={{ height: "100%" }}>
        <Grid item style={{ height: "92%" }}>
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            alignItems="stretch"
            style={{ height: "100%" }}
          >
            <Grid item style={{ width: "100%" }}>
              <Paper style={{ height: "100%", padding: 5 }}>
                <Typography variant="h3" style={{ fontWeight: "bold" }}>
                  Station Assignments
                </Typography>
                <Grid container>
                  <Grid item>
                    <Box paddingRight={4} borderRight={4}>
                      {assignments[0]}
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box paddingRight={4} paddingLeft={4} borderRight={4}>
                      {assignments[1]}
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box paddingLeft={4}>{assignments[2]}</Box>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={{ height: "8%" }}>
          <Paper style={{ height: "100%", paddingLeft: 5, paddingRight: 5 }}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              style={{ height: "100%" }}
            >
              <Grid item>
                <Typography variant="h3">Queued Teams: {queue}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h3">
                  Teams Remaining: {this.state.projectorTeamsRemaining}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    )
  }

  getStationContent() {
    if (this.state.stationTeamAssignment === -1) {
      return (
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          direction="column"
          style={{ height: "100%" }}
        >
          <Grid item>
            <Typography variant="h1">Waiting for team...</Typography>
          </Grid>
        </Grid>
      )
    } else if (this.state.stationConfirmationMode === true) {
      const hash = sha256(
        (this.state.stationAngularEnd ? this.state.stationAngularEnd : "") +
          "" +
          this.state.stationPredictedVelocity +
          "" +
          this.state.stationScore +
          "" +
          this.state.salt
      )
      return (
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          direction="column"
          style={{ height: "100%", width: "100%" }}
        >
          <Grid item>
            <Typography variant="h4">Team</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h3" style={{ fontSize: "15em" }}>
              {this.state.stationTeamAssignment}
            </Typography>
          </Grid>
          <Grid item>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              direction="column"
              spacing={0}
            >
              <Grid item>
                <Typography variant="h5">Team approval phase.</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6">
                  One team member should take a photo of this page, being
                  careful to include the validation hash, as a record of the
                  team's demo score.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <Typography variant="h4">
                  Task A (n = {this.state.stationTaskAN})
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  id="a-predicted-x"
                  label={"Predicted X"}
                  value={this.state.stationTaskAPredictedX}
                  type="number"
                  InputProps={{ inputProps: { min: 0, max: 999000 } }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                />
              </Grid>
              <Grid item>
                <TextField
                  id="a-predicted-y"
                  label={"Predicted Y"}
                  value={this.state.stationTaskAPredictedY}
                  type="number"
                  InputProps={{ inputProps: { min: 0, max: 999000 } }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                />
              </Grid>
              <Grid item>
                <TextField
                  id="a-actual-x"
                  label={"Actual X"}
                  value={this.state.stationTaskAActualX}
                  type="number"
                  InputProps={{ inputProps: { min: 0, max: 999 } }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                />
              </Grid>
              <Grid item>
                <TextField
                  id="a-actual-y"
                  label={"Actual Y"}
                  value={this.state.stationTaskAActualY}
                  type="number"
                  InputProps={{ inputProps: { min: 0, max: 999 } }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  label="X OOB"
                  labelPlacement="top"
                  control={<Switch checked={this.state.stationTaskAXoob} />}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  label="Y OOB"
                  labelPlacement="top"
                  control={<Switch checked={this.state.stationTaskAYoob} />}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <Typography variant="h4">
                  Task B (n = {this.state.stationTaskBN})
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  label={"Predicted X"}
                  value={this.state.stationTaskBPredictedX}
                  type="number"
                  InputProps={{ inputProps: { min: 0, max: 999000 } }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                />
              </Grid>
              <Grid item>
                <TextField
                  label={"Predicted Y"}
                  value={this.state.stationTaskBPredictedY}
                  type="number"
                  InputProps={{ inputProps: { min: 0, max: 999000 } }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                />
              </Grid>
              <Grid item>
                <TextField
                  label={"Actual X"}
                  value={this.state.stationTaskBActualX}
                  type="number"
                  InputProps={{ inputProps: { min: 0, max: 999 } }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                />
              </Grid>
              <Grid item>
                <TextField
                  label={"Actual Y"}
                  value={this.state.stationTaskBActualY}
                  type="number"
                  InputProps={{ inputProps: { min: 0, max: 999 } }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  label="X OOB"
                  labelPlacement="top"
                  control={<Switch checked={this.state.stationTaskBXoob} />}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  label="Y OOB"
                  labelPlacement="top"
                  control={<Switch checked={this.state.stationTaskBYoob} />}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="h6">VALIDATE: {hash}</Typography>
          </Grid>
          <Grid item>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => dispatcher.stationResults(this, false)}
                >
                  Submit Data
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() =>
                    this.setState({ stationConfirmationMode: false })
                  }
                >
                  Back
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )
    } else if (this.state.stationConfirmDisqualify === true) {
      return (
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          direction="column"
          style={{ height: "100%", width: "100%" }}
        >
          <Grid item>
            <Typography variant="h4">Team</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h3" style={{ fontSize: "15em" }}>
              {this.state.stationTeamAssignment}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h2">Really disqualify this team?</Typography>
          </Grid>
          <Grid item>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    this.setState({ stationConfirmDisqualify: false })
                  }
                >
                  Back
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => dispatcher.stationResults(this, true)}
                >
                  Disqualify Team
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )
    } else {
      return (
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          direction="column"
          style={{ height: "100%", width: "100%" }}
        >
          <Grid item>
            <Typography variant="h4">Team</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h3" style={{ fontSize: "15em" }}>
              {this.state.stationTeamAssignment}
            </Typography>
          </Grid>
          <Grid item>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <Typography variant="h4">
                  Task A (n = {this.state.stationTaskAN})
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  id="a-predicted-x"
                  label={"Predicted X"}
                  value={this.state.stationTaskAPredictedX}
                  onChange={event =>
                    this.setState({
                      stationTaskAPredictedX: event.target.value,
                    })
                  }
                  type="number"
                  InputProps={{ inputProps: { min: 0, max: 999 } }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item>
                <TextField
                  id="a-predicted-y"
                  label={"Predicted Y"}
                  value={this.state.stationTaskAPredictedY}
                  onChange={event =>
                    this.setState({
                      stationTaskAPredictedY: event.target.value,
                    })
                  }
                  type="number"
                  InputProps={{ inputProps: { min: 0, max: 999 } }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item>
                <TextField
                  id="a-actual-x"
                  label={"Actual X"}
                  value={this.state.stationTaskAActualX}
                  onChange={event =>
                    this.setState({
                      stationTaskAActualX: event.target.value,
                    })
                  }
                  type="number"
                  InputProps={{ inputProps: { min: 0, max: 999 } }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item>
                <TextField
                  id="a-actual-y"
                  label={"Actual Y"}
                  value={this.state.stationTaskAActualY}
                  onChange={event =>
                    this.setState({
                      stationTaskAActualY: event.target.value,
                    })
                  }
                  type="number"
                  InputProps={{ inputProps: { min: 0, max: 999 } }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  label="X OOB"
                  labelPlacement="top"
                  control={
                    <Switch
                      checked={this.state.stationTaskAXoob}
                      onChange={event =>
                        this.setState({
                          stationTaskAXoob: event.target.checked,
                        })
                      }
                    />
                  }
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  label="Y OOB"
                  labelPlacement="top"
                  control={
                    <Switch
                      checked={this.state.stationTaskAYoob}
                      onChange={event =>
                        this.setState({
                          stationTaskAYoob: event.target.checked,
                        })
                      }
                    />
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <Typography variant="h4">
                  Task B (n = {this.state.stationTaskBN})
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  id="b-predicted-x"
                  label={"Predicted X"}
                  value={this.state.stationTaskBPredictedX}
                  onChange={event =>
                    this.setState({
                      stationTaskBPredictedX: event.target.value,
                    })
                  }
                  type="number"
                  InputProps={{ inputProps: { min: 0, max: 999 } }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item>
                <TextField
                  id="b-predicted-y"
                  label={"Predicted Y"}
                  value={this.state.stationTaskBPredictedY}
                  onChange={event =>
                    this.setState({
                      stationTaskBPredictedY: event.target.value,
                    })
                  }
                  type="number"
                  InputProps={{ inputProps: { min: 0, max: 999 } }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item>
                <TextField
                  id="b-actual-x"
                  label={"Actual X"}
                  value={this.state.stationTaskBActualX}
                  onChange={event =>
                    this.setState({
                      stationTaskBActualX: event.target.value,
                    })
                  }
                  type="number"
                  InputProps={{ inputProps: { min: 0, max: 999 } }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item>
                <TextField
                  id="b-actual-y"
                  label={"Actual Y"}
                  value={this.state.stationTaskBActualY}
                  onChange={event =>
                    this.setState({
                      stationTaskBActualY: event.target.value,
                    })
                  }
                  type="number"
                  InputProps={{ inputProps: { min: 0, max: 999 } }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  label="X OOB"
                  labelPlacement="top"
                  control={
                    <Switch
                      checked={this.state.stationTaskBXoob}
                      onChange={event =>
                        this.setState({
                          stationTaskBXoob: event.target.checked,
                        })
                      }
                    />
                  }
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  label="Y OOB"
                  labelPlacement="top"
                  control={
                    <Switch
                      checked={this.state.stationTaskBYoob}
                      onChange={event =>
                        this.setState({
                          stationTaskBYoob: event.target.checked,
                        })
                      }
                    />
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <FormControl>
              <InputLabel htmlFor="comment-select">Comment</InputLabel>
              <Select
                style={{ minWidth: 200 }}
                autoWidth
                value={this.state.stationComment}
                onChange={event =>
                  this.setState({ stationComment: event.target.value })
                }
                inputProps={{
                  name: "comment",
                  id: "comment-select",
                }}
              >
                <MenuItem value="" />
                <MenuItem value={1}>
                  Robot appears to be controlled by student.
                </MenuItem>
                <MenuItem value={2}>Robot did not move.</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    this.setState({ stationConfirmationMode: true })
                  }
                >
                  Submit Data
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() =>
                    this.setState({ stationConfirmDisqualify: true })
                  }
                >
                  Disqualify Team
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )
    }
  }

  getAdminContent() {
    const disabledStations = []
    for (let station of this.state.adminDisabledStations) {
      disabledStations.push(
        <Chip
          key={station}
          label={"Station " + station}
          onDelete={() => dispatcher.adminToggleStation(this, station)}
        />
      )
    }
    let requested = ""
    if (this.state.adminTeamNumber > 0) {
      let teamInfo = []
      for (let key in this.state.adminTeam) {
        teamInfo.push(
          <Typography variant="body1">
            {key}: {String(this.state.adminTeam[key])}
          </Typography>
        )
      }
      requested = (
        <React.Fragment>
          <Grid item>
            <Paper style={{ padding: 10 }}>
              <Typography variant="h5">Team Info</Typography>
              {teamInfo}
            </Paper>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => dispatcher.adminCommand(this, "free")}
            >
              Free Team
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => dispatcher.adminCommand(this, "checkin")}
            >
              Check In
            </Button>
          </Grid>
        </React.Fragment>
      )
    }
    return (
      <Grid container spacing={1} direction="column">
        <Grid item>
          <Typography variant="h4">Admin Control Panel</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h5">
            Total stations: {this.state.adminStations}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h5">
            Enabled stations:{" "}
            {this.state.adminStations - this.state.adminDisabledStations.length}
          </Typography>
        </Grid>
        <Grid item>
          <Paper style={{ padding: 10 }}>
            <Typography variant="h5">Disabled Stations</Typography>
            {disabledStations}
          </Paper>
        </Grid>
        <Grid item>
          <Typography variant="h5">Disable Station</Typography>
        </Grid>
        <Grid item>
          <TextField
            id="disable-station"
            label="Station"
            value={this.state.adminStationToDisable}
            onChange={event => {
              if (
                event.target.value < 0 ||
                event.target.value > this.state.adminStations
              ) {
                return
              }
              this.setState({
                adminStationToDisable: event.target.value,
              })
            }}
            margin="normal"
            type="number"
            variant="outlined"
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              dispatcher.adminToggleStation(
                this,
                this.state.adminStationToDisable
              )
            }
          >
            Disable
          </Button>
        </Grid>
        <Grid item>
          <Typography variant="h5">Request Team</Typography>
        </Grid>
        <Grid item>
          <TextField
            id="request-team"
            label="Team"
            value={this.state.adminTeamToRequest}
            onChange={event => {
              this.setState({
                adminTeamToRequest: event.target.value,
              })
            }}
            margin="normal"
            type="number"
            variant="outlined"
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              dispatcher.adminRequestTeam(this, this.state.adminTeamToRequest)
            }
          >
            Request Team
          </Button>
        </Grid>
        {requested}
        <Grid item>
          <Typography variant="h5">
            Test layout mode is{" "}
            {this.state.adminTestLayout ? "ENABLED" : "DISABLED"}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => dispatcher.adminToggleTestLayout(this)}
          >
            {this.state.adminTestLayout
              ? "Disable Test Layout"
              : "Enable Test Layout"}
          </Button>
        </Grid>
      </Grid>
    )
  }

  getConnectingContent() {
    return (
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        direction="column"
        style={{ height: "100%", width: "100%" }}
      >
        <Grid item>
          <Typography variant="h4">
            Connecting to the socket. Please wait.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h5">
            If you see this message for more than 10 seconds, refresh the page.
          </Typography>
        </Grid>
      </Grid>
    )
  }

  getDisconnectedContent() {
    return (
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        direction="column"
        style={{ height: "100%", width: "100%" }}
      >
        <Grid item>
          <Typography variant="h4">
            Another user has logged in as this station.
          </Typography>
        </Grid>
      </Grid>
    )
  }

  render() {
    let content = this.getConnectingContent()
    let extension = ""
    if (this.state.disconnected) {
      content = this.getDisconnectedContent()
    } else if (this.state.connected) {
      if (!this.state.loggedIn) {
        content = this.getMainMenuContent()
      } else {
        if (this.state.mode === modes.station) {
          content = this.getStationContent()
          extension = "(Station " + this.state.station + ")"
        } else if (this.state.mode === modes.gatekeeper) {
          content = this.getGatekeeperContent()
        } else if (this.state.mode === modes.projector) {
          content = this.getProjectorContent()
        } else if (this.state.mode === modes.admin) {
          content = this.getAdminContent()
        }
      }
    }
    return (
      <div style={{ height: "100%" }}>
        <CssBaseline />
        <div style={{ height: "100%", display: "flex", flexFlow: "column" }}>
          <AppBar position="static" style={{ flex: "0 1 auto" }}>
            <Toolbar>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Typography variant="h6" color="inherit" noWrap>
                    ENED 1120 Project 5 Subtask 1 Demo {extension}
                  </Typography>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <Grid
            container
            justifyContent="flex-start"
            direction="column"
            spacing={0}
            style={{ flex: "1 1 auto" }}
          >
            <Grid item style={{ height: "100%" }}>
              <div style={{ height: "100%", padding: 20 }}>{content}</div>
            </Grid>
          </Grid>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          onClose={() =>
            this.setState({
              wrongPasswordOpen: false,
            })
          }
          open={this.state.wrongPasswordOpen}
          autoHideDuration={3000}
        >
          <SnackbarContent
            style={{ backgroundColor: red[600] }}
            message={
              <span
                id="wrong-password-text"
                style={{ alignItems: "center", display: "flex" }}
              >
                <ErrorIcon style={{ marginRight: 10 }} />
                Wrong password!
              </span>
            }
            action={[
              <IconButton
                key="close"
                aria-label="close"
                color="inherit"
                onClick={() =>
                  this.setState({
                    wrongPasswordOpen: false,
                  })
                }
              >
                <CloseIcon />
              </IconButton>,
            ]}
          />
        </Snackbar>
      </div>
    )
  }
}

export default App
