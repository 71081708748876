import packets from "./packets"
import modes from "./modes"

function dispatchHandshake(app) {
  // Convert to the java mode representation
  let mode = "ADMIN"
  switch (app.state.mode) {
    case modes.station:
      mode = "STATION"
      break
    case modes.gatekeeper:
      mode = "GATEKEEPER"
      break
    case modes.projector:
      mode = "PROJECTOR"
      break
    default:
      break
  }
  send(app, {
    type: packets.HANDSHAKE,
    clientType: mode,
    station: app.state.station,
    password: app.state.password,
  })
}

function dispatchGatekeeperInit(app) {
  send(app, {
    type: packets.CHECKIN_INIT,
    teamNumber: app.state.checkinTeamNumber,
  })
}

function dispatchGatekeeperConfirm(app) {
  send(app, {
    type: packets.CHECKIN_CONFIRM,
    teamNumber: app.state.checkinTeamNumber,
  })
  app.setState({
    checkinAwaitingConfirm: false,
    checkinTeamNumber: 0,
  })
}

function dispatchStationResults(app, disqualify) {
  let comment = app.state.stationComment === "" ? 0 : app.state.stationComment
  send(app, {
    type: packets.STATION_RESULT,
    taskAN: app.state.stationTaskAN,
    taskAPredictedX: app.state.stationTaskAPredictedX,
    taskAPredictedY: app.state.stationTaskAPredictedY,
    taskAActualX: app.state.stationTaskAActualX,
    taskAActualY: app.state.stationTaskAActualY,
    taskAXoob: app.state.stationTaskAXoob,
    taskAYoob: app.state.stationTaskAYoob,
    taskBN: app.state.stationTaskBN,
    taskBPredictedX: app.state.stationTaskBPredictedX,
    taskBPredictedY: app.state.stationTaskBPredictedY,
    taskBActualX: app.state.stationTaskBActualX,
    taskBActualY: app.state.stationTaskBActualY,
    taskBXoob: app.state.stationTaskBXoob,
    taskBYoob: app.state.stationTaskBYoob,
    disqualified: disqualify,
    comment: comment,
  })
  app.setState({
    stationConfirmationMode: false,
    stationConfirmDisqualify: false,
    stationTeamAssignment: -1,
    stationTaskAN: 0,
    stationTaskAPredictedX: 0,
    stationTaskAPredictedY: 0,
    stationTaskAActualX: 0,
    stationTaskAActualY: 0,
    stationTaskAXoob: false,
    stationTaskAYoob: false,
    stationTaskBN: 0,
    stationTaskBPredictedX: 0,
    stationTaskBPredictedY: 0,
    stationTaskBActualX: 0,
    stationTaskBActualY: 0,
    stationTaskBXoob: false,
    stationTaskBYoob: false,
    stationComment: "",
  })
}

function dispatchAdminToggleStation(app, station) {
  send(app, {
    type: packets.ADMIN_TOGGLE_STATION,
    station: station,
  })
}

function dispatchAdminToggleTestLayout(app) {
  send(app, {
    type: packets.ADMIN_TOGGLE_TEST_LAYOUT,
  })
}

function dispatchAdminCommand(app, command) {
  send(app, {
    type: packets.ADMIN_COMMAND,
    team: app.state.adminTeamNumber,
    command: command,
  })
}

function dispatchAdminRequestTeam(app, command) {
  send(app, {
    type: packets.ADMIN_REQUEST_TEAM,
    team: app.state.adminTeamToRequest,
  })
}

function send(app, payload) {
  app.socket.send(JSON.stringify(payload))
}

const dispatcher = {
  handshake: dispatchHandshake,
  gatekeeperInit: dispatchGatekeeperInit,
  gatekeeperConfirm: dispatchGatekeeperConfirm,
  stationResults: dispatchStationResults,
  adminToggleStation: dispatchAdminToggleStation,
  adminToggleTestLayout: dispatchAdminToggleTestLayout,
  adminCommand: dispatchAdminCommand,
  adminRequestTeam: dispatchAdminRequestTeam,
}

export default dispatcher
